<template>
    <div class="m-p">
       <ManagingRoles />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'My Companies',
 components: {
    ManagingRoles: defineAsyncComponent( () => import('@/components/Profile/Companies/ManagingRoles.vue') ),
 },
 }
 </script>